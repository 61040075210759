import { casesConstants } from '../_constants';
import { casesService } from '../_services';
import { alertActions } from '.';

export const casesActions = {
  register,
  getById,
  getByCategoryYear,
  getAll,
  update,
  delete: _delete,
  import: _import,
};

function getById(id) {

  return dispatch => {
    dispatch(request({ id }));

    casesService.getById(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(id) { return { type: casesConstants.GET_REQUEST, id } }
  function success(case_data) { return { type: casesConstants.GET_SUCCESS, case_data } }
  function failure(error) { return { type: casesConstants.GET_FAILURE, error } }
}

function getByCategoryYear(category, year) {

  return dispatch => {
    //dispatch(request({ id }));

    casesService.getByCategoryYear(category, year)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response, year))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  //function request(case_data) { return { type: casesConstants.GETALL_REQUEST, case_data } }
  function success(cases, year) { return { type: casesConstants.GETALL_SUCCESS, cases } }
  function failure(error) { return { type: casesConstants.GETALL_FAILURE, error } }
}

function register(case_data) {
  return dispatch => {
    dispatch(request(case_data));

    casesService.register(case_data)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Registration successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(case_data) { return { type: casesConstants.REGISTER_REQUEST, case_data } }
  function success(case_data) { return { type: casesConstants.REGISTER_SUCCESS, case_data } }
  function failure(error) { return { type: casesConstants.REGISTER_FAILURE, error } }
}

function update(case_data) {
  return dispatch => {
    dispatch(request(case_data));

    casesService.update(case_data)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Updated successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request(case_data) { return { type: casesConstants.REGISTER_REQUEST, case_data } }
  function success(case_data) { return { type: casesConstants.REGISTER_SUCCESS, case_data } }
  function failure(error) { return { type: casesConstants.REGISTER_FAILURE, error } }
}

function getAll(api_params) {
  return dispatch => {
    dispatch(request());

    casesService.getAll(api_params)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response))
          }
        }
      );
  };

  function request() { return { type: casesConstants.GETALL_REQUEST } }
  function success(cases) { return { type: casesConstants.GETALL_SUCCESS, cases } }
  function failure(error) { return { type: casesConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
  return dispatch => {
    dispatch(request(id));

    casesService.delete(id)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response.id))
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(id, response));
          }
        }
      );
  };

  function request(id) { return { type: casesConstants.DELETE_REQUEST, id } }
  function success(id) { return { type: casesConstants.DELETE_SUCCESS, id } }
  function failure(id, error) { return { type: casesConstants.DELETE_FAILURE, id, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _import(year_to_import) {
  return dispatch => {
    dispatch(request());

    casesService.import(year_to_import)
      .then(
        response => {
          if(response.success === true) {
            dispatch(success(response));
            dispatch(alertActions.success('Import successful'));
          }
          else if(response.errors !== undefined && Object.keys(response.errors).length > 0) {
            dispatch(failure(response));
            dispatch(alertActions.error(response));
          }
        }
      );
  };

  function request() { return { type: casesConstants.IMPORT_REQUEST } }
  function success(cases_import) { return { type: casesConstants.IMPORT_SUCCESS, cases_import } }
  function failure(error) { return { type: casesConstants.IMPORT_FAILURE, error } }
}